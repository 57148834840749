import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import axios from "axios";
import Post from "../Components/Post";
import LoadingBox from "../LoadingError/LoadingBox";
import { Helmet } from "react-helmet-async";
import MessageBox from "../LoadingError/MessageBox";
import { Store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MapsView from "../Components/MapsView";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PublicIcon from "@mui/icons-material/Public";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import { getError } from "../LoadingError/Utils";
import { toast } from "react-toastify";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import Maps from "../Components/Maps";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";

const customStylesSelect = {
  option: (provided) => ({
    ...provided,
    ":active": {
      ...provided[":active"],
      backgroundColor: "#FFE4CC",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.375rem",
    backgroundColor: "transparent",
    border: "0",
    padding: "0",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    outline: state.isFocused ? "none" : provided.outline,
  }),
};

const mutedInputStyle = {
  backgroundColor: "#e5e7eb",
  color: "#6b7280",
  borderColor: "#d1d5db",
  width: "100%",
  padding: "8px 12px",
  borderRadius: "0.375rem",
  outline: "none",
  cursor: "no-drop",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, posts: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingUser: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loadingUser: false };
    case "FETCH_FAILED":
      return { ...state, loadingUser: false, errorUser: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCategory: true };
    case "FETCHED":
      return { ...state, optionsCategory: action.payload, loading: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCategory: false,
        errorCategory: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCurrency: true };
    case "FETCHED":
      return {
        ...state,
        currencyList: action.payload,
        loadingCurrency: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCurrency: false,
        errorCurrency: action.payload,
      };
    default:
      return state;
  }
};

function HomeScreen(props) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const currentSwitch = props.statePropSd;
  const searchInput = props.statePropSearch;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isShare, setIsShare] = useState(true);

  const [pageSeek, setPageSeek] = useState(1);
  const [hasMoreSeek, setHasMoreSeek] = useState(true);

  const [isShowMap, setIsShowMap] = useState(false);

  const [isLoadingImg, setIsLoadingImg] = useState(true);

  const [isUploadImg, setIsUploadImg] = useState(false);
  const [isUploadLocation, setIsUploadLocation] = useState(false);
  const [isUploadPrice, setIsUploadPrice] = useState(false);
  const [isUploadSocialMedia, setIsUploadSocialMedia] = useState(false);

  const scrollContainerRef = useRef(null);

  const [isSubmit, setIsSubmit] = useState(false);

  // Title
  const [title, setTitle] = useState("");
  const [isShowTitleError, setIsShowTitleError] = useState(false);

  // description
  const [description, setDescription] = useState("");

  // tags
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningEmpty, setShowWarningEmpty] = useState(false);

  //category
  const [category, setCategory] = useState("");
  const [psType, setPsType] = useState(1);

  //image files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const maxImageSize = 10 * 1024 * 1024; // 10 MB limit for images
  const maxVideoSize = 200 * 1024 * 1024; // 200 MB limit for videos (or set as needed)

  const [errorMessage, setErrorMessage] = useState("");

  //product
  const [isDisabledPs, setIsDisabledPs] = useState(true);

  // coordinate and location
  const [location, setLocation] = useState("");
  const [coordinateLocation, setCoordinateLocation] = useState(null);
  const [isShowErrorCoordinateLocation, setIsShowErrorCoordinateLocation] =
    useState(false);

  // price
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");

  //contact
  const [mySocialMedia, setMySocialMedia] = useState([]);
  const [buttonMySocialMedia, setButtonMySocialMedia] = useState(false);

  const [{ loading, error, posts }, dispatch] = useReducer(reducer, {
    posts: [],
    loading: true,
    error: "",
  });

  const [allPost, setAllPost] = useState([]);

  const [allPostSeek, setAllPostSeek] = useState([]);

  const [{ dataUser, loadingUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loadingUser: false,
    errorUser: "",
  });

  const [{ loadingCategory, optionsCategory }, dispatch3] = useReducer(
    reducer3,
    {
      optionsCategory: [],
      loadingCategory: true,
      errorCategory: "",
    }
  );

  const [{ currencyList, loadingCurrency }, dispatch4] = useReducer(reducer4, {
    currencyList: [],
    loadingCurrency: true,
    errorCurrency: "",
  });

  const optionsPostType = [
    {
      value: "1",
      label:
        isShare === true ? (
          <>
            Product{" "}
            <span className="text-gray-400">- I want to offer product</span>
          </>
        ) : (
          <>
            Product{" "}
            <span className="text-gray-400">- I want to seek a product</span>
          </>
        ),
    },
    {
      value: "2",
      label:
        isShare === true ? (
          <>
            Service{" "}
            <span className="text-gray-400">- I want to offer service</span>
          </>
        ) : (
          <>
            Service{" "}
            <span className="text-gray-400">- I want to seek a service</span>
          </>
        ),
    },
    {
      value: "3",
      label:
        isShare === true ? (
          <>
            Event <span className="text-gray-400">- I want to share event</span>
          </>
        ) : (
          <>
            Event{" "}
            <span className="text-gray-400">- I want to seek an event</span>
          </>
        ),
    },
    {
      value: "4",
      label:
        isShare === true ? (
          <>
            Job <span className="text-gray-400">- I want to offer a job</span>
          </>
        ) : (
          <>
            Job <span className="text-gray-400">- I need to find a job</span>
          </>
        ),
    },
    {
      value: "5",
      label:
        isShare === true ? (
          <>
            Insight{" "}
            <span className="text-gray-400">
              - I want to share some insight/review
            </span>
          </>
        ) : (
          <>
            Insight{" "}
            <span className="text-gray-400">- I need some insight/review</span>
          </>
        ),
    },
  ];

  // Mengambil data post berdasarkan halaman, pencarian, dan switch saat ini
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });

      let userId = null;
      if (dataUser) {
        userId = dataUser.user_id;
      }

      let tempPage = currentSwitch === 1 ? page : pageSeek;

      let tempTypePost =
        currentSwitch === 1
          ? [
              { post_type: 1, sd_post_type: 1 },
              { post_type: 2, sd_post_type: 1 },
              { post_type: 3, sd_post_type: 1 },
              { post_type: 4, sd_post_type: 1 },
              { post_type: 5, sd_post_type: 1 },
            ]
          : [
              { post_type: 1, sd_post_type: 2 },
              { post_type: 2, sd_post_type: 2 },
              { post_type: 3, sd_post_type: 2 },
              { post_type: 4, sd_post_type: 2 },
              { post_type: 5, sd_post_type: 2 },
            ];

      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BACKEND_DOMAIN
          }/api/posts/search?query=${searchInput}&row=10&page=${tempPage}&userId=${userId}&typePost=${JSON.stringify(
            tempTypePost
          )}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        if (response.data.data.length < 10) {
          if (currentSwitch === 1) {
            setHasMore(false);
          } else {
            setHasMoreSeek(false);
          }
        }

        dispatch({
          type: "FETCHED",
          payload: [...posts, ...response.data.data],
        });

        if (allPost.length === 0 && currentSwitch === 1) {
          setAllPost((prevPost) => [...prevPost, ...response.data.data]);
        } else if (allPostSeek.length === 0 && currentSwitch === 2) {
          setAllPostSeek((prevPost) => [...prevPost, ...response.data.data]);
        }
      } catch (err) {
        dispatch({
          type: "FETCH_FAILED",
          payload: err.message,
        });
      }
    };

    if (
      !loadingUser &&
      ((currentSwitch === 1 && allPost.length === 0) ||
        (currentSwitch === 2 && allPostSeek.length === 0))
    ) {
      fetchData();
    }
  }, [searchInput, page, pageSeek, dataUser, currentSwitch]);

  const loadMorePosts = () => {
    if (
      (hasMore && currentSwitch === 1) ||
      (hasMoreSeek && currentSwitch === 2)
    ) {
      let tempPage = currentSwitch === 1 ? page + 1 : pageSeek + 1;

      const fetchData = async () => {
        let userId = null;
        if (dataUser) {
          userId = dataUser.user_id;
        }

        let tempTypePost =
          currentSwitch === 1
            ? [
                { post_type: 1, sd_post_type: 1 },
                { post_type: 2, sd_post_type: 1 },
                { post_type: 3, sd_post_type: 1 },
                { post_type: 4, sd_post_type: 1 },
                { post_type: 5, sd_post_type: 1 },
              ]
            : [
                { post_type: 1, sd_post_type: 2 },
                { post_type: 2, sd_post_type: 2 },
                { post_type: 3, sd_post_type: 2 },
                { post_type: 4, sd_post_type: 2 },
                { post_type: 5, sd_post_type: 2 },
              ];

        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_BACKEND_DOMAIN
            }/api/posts/search?query=${searchInput}&row=10&page=${tempPage}&userId=${userId}&typePost=${JSON.stringify(
              tempTypePost
            )}`,
            {
              auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
              },
            }
          );

          if (response.data.data.length < 10) {
            if (currentSwitch === 1) {
              setHasMore(false);
            } else {
              setHasMoreSeek(false);
            }
          }

          if (currentSwitch === 1) {
            setAllPost((prevPost) => [...prevPost, ...response.data.data]);
          } else {
            setAllPostSeek((prevPost) => [...prevPost, ...response.data.data]);
          }
        } catch (err) {}
      };

      fetchData();

      if (currentSwitch === 1) {
        setPage((prevPage) => prevPage + 1);
        // Only increment page if there are more posts to load
      } else {
        setPageSeek((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch2({ type: "FETCHING" });

        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );

          dispatch2({ type: "FETCHED", payload: data.data });
        } catch (error) {
          dispatch2({ type: "FETCH_FAILED", payload: error.message });
        }
      }
    };

    fetchData();
  }, []);

  const [selectedPsType, setSelectedPsType] = useState(
    optionsPostType[0].value
  );

  const [selectedCategory, setSelectedCategory] = useState(
    optionsCategory?.[0]?.value || ""
  );

  useEffect(() => {
    let psTypeWord = selectedPsType;

    const fetchData = async () => {
      dispatch3({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCategoryOptions?psType=${psTypeWord}`,

          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );
        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [psType, selectedPsType]);

  useEffect(() => {
    if (optionsCategory[0] && optionsCategory[0].value !== "") {
      setSelectedCategory(optionsCategory[0].value);
    }
  }, [optionsCategory]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch4({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCurrencyOptions`,

          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch4({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch4({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {

  // }, [allPost,allPostSeek, currentSwitch]);

  const handleCheckboxChange = () => {
    setIsShare(!isShare);
  };

  function SetNoPosts({ myKey }) {
    if (myKey === 1 && allPost.length === 0) {
      return <p className="mt-5">No post exists!</p>;
    } else if (myKey === 2 && allPostSeek.length === 0) {
      return <p className="mt-5">No post exists!</p>;
    } else {
      return <div></div>;
    }
  }

  const toggleModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  function handleCloseUploadModal() {
    setShowUploadModal(false);
    setIsUploadImg(false);
    setIsUploadLocation(false);
    setIsUploadPrice(false);
    setIsUploadSocialMedia(false);
    setIsShare(true);
    setButtonMySocialMedia(false);
    setCurrency("");
    setShowWarning(false);
    setShowWarningEmpty(false);
    setTitle("");
    setDescription("");
    setTagInput("");
    setTags([]);
    setSelectedPsType(optionsPostType[0].value);
    setSelectedCategory(optionsCategory[0].value);
    setSelectedFiles([]);
    setErrorMessage("");
    setIsSubmit(false);
  }

  const handlePositionFromMaps = (data, province) => {
    setLocation(province);
    setCoordinateLocation(data);
  };

  const toggleMySocialMedia = () => {
    if (dataUser.length !== 0) {
      // const userSocialMedia = dataUser.contact.map((item) => ({
      //   value: item.contact_id.toString(),
      //   label: item.contact_name,
      //   image: item.contact_image,
      //   link: item.link,
      // }));

      // setMySocialMedia(userSocialMedia);
      setButtonMySocialMedia(!buttonMySocialMedia);
    }
  };

  useEffect(() => {
    if (dataUser.length !== 0 && dataUser.contact) {
      const userSocialMedia = dataUser.contact.map((item) => ({
        value: item.contact_id.toString(),
        label: item.contact_name,
        image: item.contact_image,
        link: item.link,
      }));
      setMySocialMedia(userSocialMedia);
    }
  }, [dataUser]);

  const fileChange = (e) => {
    const files = e.target.files;
    const newSelectedFiles = [];
    let newErrorMessage = "";

    if (files.length <= 10) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file && file.type.startsWith("image/")) {
          // Handle image files
          if (file.size <= maxImageSize) {
            newSelectedFiles.push(file);
          } else {
            newErrorMessage = `Image ${file.name} exceeds the maximum size limit of 10MB. Please select a smaller image.`;
          }
        } else if (file && file.type.startsWith("video/")) {
          // Handle video files
          if (file.size <= maxVideoSize) {
            newSelectedFiles.push(file);
          } else {
            newErrorMessage = `Video ${file.name} exceeds the maximum size limit of 200MB. Please select a smaller video.`;
          }
        } else {
          newErrorMessage = `Please select an image or video file.`;
        }
      }
    } else {
      newErrorMessage = `The number of files exceeds the maximum limit of 10`;
    }

    if (newErrorMessage) {
      // If there is an error, prevent further processing and display the error message.
      setErrorMessage(newErrorMessage);
    } else {
      // If no errors, proceed to set the selected files.
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
      setErrorMessage(""); // Clear any previous error messages
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current !== null) {
      scrollContainerRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "end", // use inline for horizontal scrolling
      });
    }
  }, [selectedFiles]);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const newSelectedFiles = [];
    let newErrorMessage = "";

    if (files.length <= 10) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file) {
          if (file.type.startsWith("image/") && file.size <= maxImageSize) {
            // Add image file if it meets the size criteria
            newSelectedFiles.push(file);
          } else if (
            file.type.startsWith("video/") &&
            file.size <= maxVideoSize
          ) {
            // Add video file if it meets the size criteria
            newSelectedFiles.push(file);
          } else if (
            !file.type.startsWith("image/") &&
            !file.type.startsWith("video/")
          ) {
            // Error for unsupported file types
            newErrorMessage = `Please select an image or video file.`;
          } else if (
            file.type.startsWith("image/") &&
            file.size > maxImageSize
          ) {
            // Error for oversized image
            newErrorMessage = `Image ${file.name} exceeds the maximum size limit of 10MB. Please select smaller images.`;
          } else if (
            file.type.startsWith("video/") &&
            file.size > maxVideoSize
          ) {
            // Error for oversized video
            newErrorMessage = `Video ${file.name} exceeds the maximum size limit of 200MB. Please select smaller videos.`;
          }
        }
      }
    } else {
      newErrorMessage = `The number of files exceeds the maximum limit of 10`;
    }

    if (newErrorMessage) {
      // If there is an error, prevent further processing and display the error message.
      setErrorMessage(newErrorMessage);
    } else {
      // If no errors, proceed to set the selected files.
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
      setErrorMessage(""); // Clear any previous error messages
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeSelectedFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="rounded-full w-12 h-12"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  useEffect(() => {
    const img = new Image();
    img.src = dataUser.picture;
    img.onload = () => setIsLoadingImg(false);
  }, [dataUser.picture]);

  function ProfilePicture() {
    return (
      <>
        {isLoadingImg ? (
          <div
            className={`rounded-full w-12 h-12 ${
              isLoadingImg ? "animate-pulse bg-slate-400" : ""
            }`}
          ></div>
        ) : (
          <img
            className="rounded-full w-12 h-12"
            src={dataUser.picture}
            alt="profilepicture"
          />
        )}
      </>
    );
  }

  const SelectBoxPsType = () => {
    return (
      <Listbox
        // value={selectedOptions}
        onChange={setSelectedPsType}
      >
        <div className="w-full rounded-lg focus:outline-none focus:border-[#F95C3D] text-black">
          <Listbox.Button
            className={clsx(
              "w-full h-full text-left rounded-lg bg-white py-2 px-1 text-black text-sm lg:text-base",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
            )}
            style={{
              border: "0.1px solid gainsboro",
            }}
          >
            {
              optionsPostType.find((option) => option.value === selectedPsType)
                ?.label
            }
          </Listbox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            anchor="bottom"
            transition
            className={clsx(
              "z-[1] absolute w-full mt-2 rounded-lg bg-white shadow-2xl py-2 empty:invisible",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
            )}
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {optionsPostType.map((option) => (
              <Listbox.Option
                key={option.id}
                value={option.value}
                // disabled={selectedOption.unavailable}
                className={({ active }) =>
                  `group flex cursor-pointer items-center gap-2 py-2 px-1 select-none text-black text-sm lg:text-base ${
                    active ? "bg-[#F5F5F5]" : ""
                  }`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    );
  };

  const SelectBoxCategory = () => {
    return (
      <Listbox
        // value={selectedOptions}
        onChange={setSelectedCategory}
      >
        <div className="w-full rounded-lg focus:outline-none focus:border-[#F95C3D] text-black">
          <Listbox.Button
            className={clsx(
              "w-full h-full text-left rounded-lg bg-white py-2 px-1 text-black text-sm lg:text-base",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
            )}
            style={{
              border: "0.1px solid gainsboro",
            }}
          >
            {
              optionsCategory.find(
                (option) => option.value === selectedCategory
              )?.label
            }
          </Listbox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            anchor="bottom"
            transition
            className={clsx(
              "z-[1] absolute w-full mt-2 rounded-lg bg-white shadow-2xl py-2 empty:invisible",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
            )}
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {optionsCategory.map((option) => (
              <Listbox.Option
                key={option.id}
                value={option.value}
                // disabled={selectedOption.unavailable}
                className={({ active }) =>
                  `group flex cursor-pointer items-center gap-2 py-2 px-1 select-none text-black text-sm lg:text-base ${
                    active ? "bg-[#F5F5F5]" : ""
                  }`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    );
  };

  // Title
  const handleTitleChange = (e) => {
    const input = e.target.value;
    const wordCount = input.trim().split(/\s+/).length;

    if (wordCount <= 6) {
      setIsShowTitleError(false);
      setTitle(input);
    } else {
      setIsShowTitleError(true);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setIsSubmit(true);

    // if (coordinateLocation === null && location === null) {
    //   setIsShowErrorCoordinateLocation(true);
    //   const element = targetRef.current;
    //   const topOffset = element.offsetTop;
    //   const elementHeight = element.offsetHeight;
    //   const windowHeight = window.innerHeight;

    //   const scrollTo = topOffset - windowHeight / 2 + elementHeight / 2;

    //   window.scrollTo({
    //     top: scrollTo,
    //     behavior: "smooth",
    //   });
    //   return;
    // } else {
    //   setIsShowErrorCoordinateLocation(false);
    // }

    // if (sdType.value === 1) {
    //   if (selectedFiles.length === 0) {
    //     setErrorMessage("Share needs to have at least 1 image!");
    //     return;
    //   }
    // }

    let sdTypeValue = isShare ? "1" : "0";
    let psTypeValue = selectedPsType;
    let categoryValue = selectedCategory;
    let currencyValue = currency && currency.value ? currency.value : null;
    let lngValue = coordinateLocation ? coordinateLocation.lng : null;
    let latValue = coordinateLocation ? coordinateLocation.lat : null;

    let tagsNew = tags.reduce((acc, curr, index) => {
      acc[index.toString()] = `#${curr}`;
      return acc;
    }, {});

    tagsNew = JSON.stringify(tagsNew);

    console.log("sdTypeValue", sdTypeValue);
    console.log("psTypeValue", psTypeValue);
    console.log("categoryValue", categoryValue);
    console.log("currencyValue", currencyValue);
    console.log("lngValue", lngValue);
    console.log("latValue", latValue);
    return;

    try {
      dispatch({
        type: "ADD_REQUEST",
      });
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/addPost`,
        {
          name: title,
          ...(currencyValue != null &&
            currencyValue !== "" && { currency: currencyValue }),
          ...(price != null && price !== "" && { price: price }),

          category: categoryValue,
          sd_type_post: sdTypeValue,
          type_post: psTypeValue,
          location: location,
          description: description,
          contactRef: buttonMySocialMedia,
          long: lngValue,
          lat: latValue,
          tag: tagsNew,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      dispatch({
        type: "ADD_SUCCESS",
      });

      let postId = data.data.postId;

      if (selectedFiles.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("files", selectedFiles[i]);
        }

        formData.append("postId", postId);
        await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/uploadPostImage`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      toast.success("Post added successfully!");
      setIsSubmit(false);
      navigate("/");
    } catch (err) {
      dispatch({
        type: "ADD_FAIL",
      });
      console.log(err);
      toast.error(getError(err));
    }
  };

  // Tags
  const inputRef = useRef(null);
  const addTag = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    let tempTag = tagInput.trim();
    if (tempTag !== "" && tags.length < 4 && !showWarning) {
      setTags([...tags, tagInput]);
      setTagInput("");
      setShowWarning(false);
      setShowWarningEmpty(false);
    } else if (tags.length >= 4) {
      setShowWarning(true);
      setShowWarningEmpty(false);
    } else if (tempTag === "") {
      setShowWarningEmpty(true);
    }
  };

  const removeTag = (index) => {
    setShowWarning(false);
    setTags(tags.filter((tag, idx) => idx !== index));
  };

  useEffect(() => {
    if (currencyList.length > 0 && !currency) {
      setCurrency(currencyList[0]);
    }
  }, [currency, currencyList]);

  return (
    <>
      <div className="h-full">
        <Helmet>
          <title>Home | SiapHub</title>
        </Helmet>
        <div className="grid grid-cols-3 gap-4"></div>
        <div className="h-full">
          {loading ? (
            <div className="flex justify-center pt-6 ">
              <LoadingBox />
            </div>
          ) : error ? (
            <MessageBox severity="error">{error}</MessageBox>
          ) : isShowMap ? (
            // <div className="flex h-full flex-col">
            <>
              <div
                className="fixed w-8 h-8 top-20 left-2 z-[401] shadow-md flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                onClick={() => setIsShowMap(false)}
              >
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="scale-50"
                >
                  <path
                    d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="h-full">
                <MapsView posts={currentSwitch === 1 ? allPost : allPostSeek} />
              </div>
            </>
          ) : (
            // </div>
            <>
              {currentSwitch === 1 ? (
                <div className="h-full">
                  <div className="sm:mb-12 h-full">
                    <InfiniteScroll
                      dataLength={allPost.length}
                      next={loadMorePosts}
                      scrollThreshold={0.9}
                      hasMore={hasMore}
                      // height={
                      //   window.innerHeight > 870
                      //     ? window.innerHeight - 80
                      //     : window.innerHeight - 100
                      // }
                      height={"92vh"}
                      loader={
                        <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                          <LoadingBox />
                        </div>
                      }
                    >
                      <div className="flex justify-center mb-20 lg:mb-0 ">
                        <div className="grow flex justify-center lg:mr-3 lg:justify-end mb-2">
                          <div className="flex-col flex-wrap">
                            {!userInfo ? (
                              <></>
                            ) : (
                              <div className="card-new-post xs:w-screen-90 relative overflow-hidden ">
                                <div class="flex items-center">
                                  <div class="flex-none w-14">
                                    <SetProfilePicture />
                                  </div>
                                  <div class="flex-initial w-96">
                                    <div
                                      className="card-input-post text-gray-400 cursor-text"
                                      onClick={toggleModal}
                                    >
                                      What's on your mind, {dataUser.name}?
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* {dataUser.picture} */}

                            {allPost.length > 0 ? (
                              allPost.map((post, index) => (
                                <Post key={post.slug} post={post}></Post>
                              ))
                            ) : (
                              <SetNoPosts myKey={1} />
                            )}
                          </div>
                        </div>

                        <div className="flex-col flex-wrap w-2/5 lg:mx-3 hidden mt-3 lg:flex sticky top-3 h-[90vh]">
                          <MapsView posts={allPost} />
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>

                  <div
                    className="fixed bottom-20 right-2 w-10 h-10 rounded-full bg-white flex lg:hidden justify-center items-center z-10 text-gray-500"
                    style={{ boxShadow: "0px 2px 2px 2px gainsboro" }}
                    onClick={() => setIsShowMap(true)}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`scale-125 hover:cursor-pointer`}
                    >
                      <circle cx="15" cy="15" r="15" fill="none" />
                      <path
                        d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="h-full">
                  <div className="sm:mb-5 h-full">
                    <InfiniteScroll
                      dataLength={allPostSeek.length}
                      next={loadMorePosts}
                      scrollThreshold={0.9}
                      hasMore={hasMoreSeek}
                      // height={
                      //   window.innerHeight > 870
                      //     ? window.innerHeight - 80
                      //     : window.innerHeight - 100
                      // }
                      height={"92vh"}
                      loader={
                        <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                          <LoadingBox />
                        </div>
                      }
                    >
                      <div className="flex justify-center ">
                        <div className="grow flex justify-center lg:mr-3 lg:justify-end mb-2">
                          <div className="flex-col flex-wrap">
                            {!userInfo ? (
                              <></>
                            ) : (
                              <div className="card-new-post xs:w-screen-90 relative overflow-hidden ">
                                <div class="flex items-center">
                                  <div class="flex-none w-14">
                                    <SetProfilePicture />
                                  </div>
                                  <div class="flex-initial w-96 items-center">
                                    <div
                                      className="card-input-post text-gray-400 cursor-text"
                                      onClick={toggleModal}
                                    >
                                      What's on your mind, {dataUser.name}?
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {allPostSeek.length > 0 ? (
                              allPostSeek.map((post, index) => (
                                <Post key={post.slug} post={post}></Post>
                              ))
                            ) : (
                              <SetNoPosts myKey={2} />
                            )}
                          </div>
                        </div>

                        <div className="flex-col flex-wrap w-2/5 lg:mx-3 hidden mt-3 lg:flex sticky top-3 h-[90vh]">
                          <MapsView posts={allPostSeek} />
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>

                  <div
                    className="fixed bottom-20 right-2 w-10 h-10 rounded-full bg-white flex lg:hidden justify-center items-center z-10 text-gray-500"
                    style={{ boxShadow: "0px 2px 2px 2px gainsboro" }}
                    onClick={() => setIsShowMap(true)}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`scale-125 hover:cursor-pointer`}
                    >
                      <circle cx="15" cy="15" r="15" fill="none" />
                      <path
                        d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        open={showUploadModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style p-5 bg-white rounded-lg shadow-lg max-w-md mx-auto ">
          <IconButton
            aria-label="close"
            onClick={handleCloseUploadModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* <form className="w-full rounded-lg" onSubmit={submitHandler}> */}
          <form className="" onSubmit={submitHandler}>
            {isUploadImg ? (
              <>
                {/* Choose Multiple Image */}
                <div>
                  <div
                    className="w-8 h-8 z-[401] flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                    onClick={() => setIsUploadImg(false)}
                    style={{
                      border: "0.1px solid gainsboro",
                    }}
                  >
                    <svg
                      width="30"
                      height="26"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="scale-50"
                    >
                      <path
                        d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="items-center my-2 text-center">
                    Upload your images and videos
                    <p className="text-gray-400 text-sm">Max. 10 files</p>
                  </div>
                </div>
                <div className="flex w-full my-2 overflow-x-auto">
                  {selectedFiles.length > 0 && selectedFiles.length <= 10 ? (
                    <>
                      <div className="flex space-x-2">
                        <div className="flex space-x-2">
                          {selectedFiles.map((file, index) => (
                            <div className="flex justify-center" key={index}>
                              <div className="relative w-32 h-32 rounded-lg">
                                {file.type.startsWith("image/") ? (
                                  <img
                                    className="w-full h-full object-contain"
                                    src={window.URL.createObjectURL(file)}
                                    alt={`postImage-${index}`}
                                  />
                                ) : file.type.startsWith("video/") ? (
                                  <video
                                    className="w-full h-full object-contain"
                                    src={window.URL.createObjectURL(file)}
                                    controls
                                    alt={`postVideo-${index}`}
                                  />
                                ) : null}

                                <IconButton
                                  aria-label="close"
                                  sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                  }}
                                  onClick={() => removeSelectedFile(index)}
                                  title={`Remove ${
                                    file.type.startsWith("image/")
                                      ? "Image"
                                      : "Video"
                                  }`}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                          {errorMessage && (
                            <div className="flex justify-center text-red-500 text-sm">
                              *{errorMessage}
                            </div>
                          )}
                        </div>
                        <div ref={scrollContainerRef}>
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-lg bg-white font-semibold text-[#F95C3D]"
                            id="file-upload-2"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                          >
                            <div className="w-32 h-32 flex justify-center items-center rounded-lg border-2 border-dashed border-gray-900/25">
                              <AddIcon
                                className="text-gray-500"
                                fontSize="large"
                              />
                              <input
                                id="file-upload"
                                accept="image/*,video/*"
                                type="file"
                                className="sr-only static"
                                onChange={fileChange}
                                multiple
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : selectedFiles.length === 0 ? (
                    <div
                      className="w-full my-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                      id="file-upload-2"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        <div className="flex justify-center text-gray-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 32 32"
                            viewBox="0 0 32 32"
                            id="photos"
                            className="w-14"
                          >
                            <path
                              fill="#D1D5DB"
                              d="M26,7h-1V6c0-1.6543-1.3457-3-3-3H6C4.3457,3,3,4.3457,3,6v16c0,1.6543,1.3457,3,3,3h1v1c0,1.6543,1.3457,3,3,3h16c1.6543,0,3-1.3457,3-3V10C29,8.3457,27.6543,7,26,7z M6,5h16c0.5518,0,1,0.4487,1,1v5.6237l-6.1006,6.973l-6.249-5.356c-0.3965-0.3403-0.9883-0.3179-1.3574,0.0522L5,17.5859V6C5,5.4487,5.4482,5,6,5z M27,26c0,0.5513-0.4482,1-1,1H10c-0.5518,0-1-0.4487-1-1v-1h13c1.6543,0,3-1.3457,3-3V9h1c0.5518,0,1,0.4487,1,1V26z"
                            ></path>
                            <path
                              fill="#D1D5DB"
                              d="M16,13c1.6543,0,3-1.3457,3-3s-1.3457-3-3-3s-3,1.3457-3,3S14.3457,13,16,13z"
                            ></path>
                          </svg>
                        </div>

                        <div className="mt-4 flex justify-center text-sm text-gray-600">
                          <div className="relative rounded-lg bg-white font-semibold">
                            <span>Drag and drop files to upload</span>
                          </div>
                          {/* <p className="pl-1">or drag and drop</p> */}
                        </div>
                        <p className="text-xs text-gray-600">
                          PNG, JPG up to 10MB (per image) Video up to 200MB (per
                          video)
                        </p>
                        <label
                          htmlFor="file-upload"
                          className="flex justify-center"
                        >
                          <div
                            className="rounded-lg my-2 p-2 text-xs text-gray-600 cursor-pointer hover:bg-[#F5F5F5]"
                            style={{
                              border: "0.1px solid gainsboro",
                            }}
                          >
                            Select files
                          </div>
                          <input
                            id="file-upload"
                            accept="image/*,video/*"
                            type="file"
                            className="sr-only static"
                            onChange={fileChange}
                            style={{ marginBottom: "5%" }}
                            multiple
                          />
                        </label>
                        {errorMessage && (
                          <div className="flex justify-center text-red-500 text-sm">
                            *{errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex space-x-2">
                        <div className="flex space-x-2">
                          {selectedFiles.map((file, index) => (
                            <div className="flex justify-center" key={index}>
                              <div className="relative w-32 h-32 rounded-lg">
                                {file.type.startsWith("image/") ? (
                                  <img
                                    className="w-full h-full object-contain"
                                    src={window.URL.createObjectURL(file)}
                                    alt={`postImage-${index}`}
                                  />
                                ) : file.type.startsWith("video/") ? (
                                  <video
                                    className="w-full h-full object-contain"
                                    src={window.URL.createObjectURL(file)}
                                    controls
                                    alt={`postVideo-${index}`}
                                  />
                                ) : null}

                                <IconButton
                                  aria-label="close"
                                  sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                  }}
                                  onClick={() => removeSelectedFile(index)}
                                  title={`Remove ${
                                    file.type.startsWith("image/")
                                      ? "Image"
                                      : "Video"
                                  }`}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                          {errorMessage && (
                            <div className="flex justify-center text-red-500 text-sm">
                              *{errorMessage}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : isUploadLocation ? (
              <div className="h-[500px]">
                <div
                  className="w-8 h-8 z-[401] absolute flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                  onClick={() => setIsUploadLocation(false)}
                  style={{
                    border: "0.1px solid gainsboro",
                  }}
                >
                  <svg
                    width="30"
                    height="26"
                    viewBox="0 0 30 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-50"
                  >
                    <path
                      d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <Maps
                  position={coordinateLocation}
                  sendData={handlePositionFromMaps}
                />
              </div>
            ) : isUploadPrice ? (
              <>
                <div
                  className="w-8 h-8 z-[401] flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                  onClick={() => setIsUploadPrice(false)}
                  style={{
                    border: "0.1px solid gainsboro",
                  }}
                >
                  <svg
                    width="30"
                    height="26"
                    viewBox="0 0 30 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-50"
                  >
                    <path
                      d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="flex items-center my-2">
                  <Tooltip title="The price of the post (product/event tickets/etc) (optional)">
                    <label
                      className="font-bold text-sm lg:text-base"
                      style={{ width: "35%" }}
                    >
                      Price
                    </label>
                  </Tooltip>
                  <div className="flex rounded-lg w-full">
                    {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div> */}
                    <div
                      className="flex items-center border-l border-t border-b rounded-l-lg text-gray-700 focus:outline-none"
                      // style={{
                      //   border: "0.1px solid gainsboro",
                      // }}
                    >
                      <Select
                        id="currency"
                        name="currency"
                        options={currencyList}
                        className="w-24 text-sm lg:text-base"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#FFE4CC",
                            primary: "#F95C3D",
                          },
                        })}
                        styles={customStylesSelect}
                        isSearchable
                        value={currency}
                        onChange={setCurrency}
                      />
                    </div>
                    <input
                      id="price"
                      name="price"
                      type="text"
                      placeholder="0.00"
                      className="w-full border rounded-r-lg px-3 py-2 focus:outline-none text-sm lg:text-base"
                    />
                  </div>
                </div>
              </>
            ) : isUploadSocialMedia ? (
              <>
                <div
                  className="w-8 h-8 z-[401] flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                  onClick={() => setIsUploadSocialMedia(false)}
                  style={{
                    border: "0.1px solid gainsboro",
                  }}
                >
                  <svg
                    width="30"
                    height="26"
                    viewBox="0 0 30 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-50"
                  >
                    <path
                      d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <Tooltip title="The contacts to show for the post (optional)">
                    <label
                      className="font-bold text-sm lg:text-base"
                      style={{ width: "50%" }}
                    >
                      Social Media
                    </label>
                  </Tooltip>
                  {mySocialMedia.length === 0 ? (
                    <label className="flex cursor-not-allowed select-none items-center text-sm lg:text-base">
                      <div className="relative">
                        <input
                          type="checkbox"
                          checked={buttonMySocialMedia}
                          onChange={toggleMySocialMedia}
                          className="sr-only peer"
                          disabled
                        />
                        <div className="block h-6 w-10 lg:h-8 lg:w-14 rounded-full transition-colors duration-100 bg-gray-300"></div>
                        <div className="dot absolute top-1 left-1 h-4 w-4 lg:h-6 lg:w-6 rounded-full bg-[#F5F5F5] shadow-md transition-transform duration-100"></div>
                      </div>
                    </label>
                  ) : (
                    <label className="flex cursor-pointer select-none items-center text-sm lg:text-base">
                      <div className="relative">
                        <input
                          type="checkbox"
                          checked={buttonMySocialMedia}
                          onChange={toggleMySocialMedia}
                          className="sr-only peer"
                        />
                        <div
                          className={`block h-6 w-10 lg:h-8 lg:w-14 rounded-full transition-colors duration-100 ${
                            buttonMySocialMedia ? "bg-[#F95C3D]" : "bg-gray-300"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute top-1 left-1 h-4 w-4 lg:h-6 lg:w-6 rounded-full bg-white shadow-md transition-transform duration-100 ${
                            buttonMySocialMedia ? "translate-x-4" : ""
                          }`}
                        ></div>
                      </div>
                    </label>
                  )}
                </div>
                <div className="text-gray-400 text-sm">
                  Show my social media contacts on this post
                </div>

                {mySocialMedia.length === 0 ? (
                  <div>
                    <p className="text-gray-400 text-sm">
                      You don't have a contact, please add contacts in update
                      profile page to show your social media in this post
                    </p>
                    <div
                      onClick={() => navigate("/update_profile")}
                      className="flex justify-end text-[#F95C3D] text-sm cursor-pointer"
                    >
                      Go to Update Profile Page
                      <ArrowOutwardIcon style={{ fontSize: "medium" }} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="my-5">
                  {buttonMySocialMedia &&
                    mySocialMedia.map((selectedOption) => (
                      <div
                        className="flex flex-row mt-2 items-center"
                        key={selectedOption.value}
                      >
                        <label
                          className="text-sm lg:text-base"
                          style={{ width: "40%" }}
                        >
                          {selectedOption.label}
                        </label>
                        <input
                          type="text"
                          required
                          value={selectedOption.link}
                          readOnly
                          style={mutedInputStyle}
                          className="w-full border rounded-lg px-3 py-2 focus:outline-none text-sm lg:text-base"
                        />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center my-2">
                  <SetProfilePicture />
                  <p className="leading-tight ml-2">
                    <div className="flex items-center">
                      <span>{dataUser.name}</span>
                      <div className="flex items-center ml-2">
                        {dataUser.verified ? (
                          <img
                            className="w-4 h-4"
                            alt="verified"
                            src="../../images/icon_done.png"
                          ></img>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </p>
                </div>
                <Typography
                  id="modal-modal-title"
                  component="h1"
                  className="font-semibold"
                >
                  {/* Share or seek */}
                  <label
                    className="rounded-lg shadow-card relative inline-flex cursor-pointer select-none items-center justify-center bg-white mt-2 text-sm lg:text-base"
                    style={{
                      border: "0.1px solid gainsboro",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={isShare}
                      onChange={handleCheckboxChange}
                    />
                    <span
                      className={`flex items-center space-x-[6px] rounded-lg py-2 px-[18px] text-sm lg:text-base font-medium transition-colors duration-100 ${
                        isShare
                          ? "text-primary bg-[#F5F5F5]"
                          : "text-body-color"
                      }`}
                    >
                      Share
                    </span>
                    <span
                      className={`flex items-center space-x-[6px] rounded-lg py-2 px-[18px] text-sm lg:text-base font-medium transition-transform duration-100 ${
                        !isShare
                          ? "text-primary bg-[#F5F5F5]"
                          : "text-body-color"
                      }`}
                    >
                      Seek
                    </span>
                  </label>

                  {/* Post Type */}
                  <div className="flex items-center my-2">
                    <Tooltip title="Type of the post">
                      <label
                        className="font-bold text-sm lg:text-base"
                        style={{ width: "35%" }}
                      >
                        Post Type <span style={{ color: "red" }}>*</span>
                      </label>
                    </Tooltip>
                    <div className="w-full relative">
                      <SelectBoxPsType />
                    </div>
                  </div>

                  {/* Category */}
                  <div className="flex items-center my-2">
                    <Tooltip title="Category of the post">
                      <label
                        className="font-bold text-sm lg:text-base"
                        style={{ width: "35%" }}
                      >
                        Category <span style={{ color: "red" }}>*</span>
                      </label>
                    </Tooltip>
                    <div className="w-full relative">
                      <SelectBoxCategory />
                    </div>
                  </div>

                  {/* Input for title */}
                  <input
                    className="w-full border rounded-lg px-3 py-2 my-2 focus:outline-none text-sm lg:text-base"
                    style={{
                      border: "0.1px solid gainsboro",
                    }}
                    // spellCheck="false"
                    placeholder="Title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    required
                  />
                  {isShowTitleError && (
                    <div className="text-red-500 text-sm">
                      *Title cannot exceed 6 words!
                    </div>
                  )}

                  {/* Textarea for description */}
                  <textarea
                    className="w-full border rounded-lg px-3 py-2 focus:outline-none text-sm lg:text-base"
                    style={{
                      border: "0.1px solid gainsboro",
                      resize: "none",
                      height: "120px",
                    }}
                    spellCheck="false"
                    placeholder="Describe everything about this post here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />

                  {/* Character count */}
                  {/* <div className="icons flex text-gray-500 m-2">
              <div className="count ml-auto text-gray-400 text-xs font-semibold">
                {description.length}/300
              </div>
            </div> */}

                  {/* Tags */}
                  <div className="flex flex-col my-2">
                    <div className="flex items-center justify-between">
                      <Tooltip title="The hastags for the post (optional)">
                        <label
                          className="font-bold text-sm lg:text-base"
                          style={{ width: "50%" }}
                        >
                          Tags{" "}
                        </label>
                      </Tooltip>
                      <div className="border-l border-t border-b rounded-l-lg px-3 py-2 text-gray-700 focus:outline-none">
                        #
                      </div>
                      <input
                        className="w-full border rounded-r-lg px-3 py-2 focus:outline-none text-sm lg:text-base"
                        type="text"
                        placeholder="Tags"
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        ref={inputRef}
                      />
                      <Tooltip title="Add Tag">
                        <button
                          type="button"
                          className="rounded-lg ml-2 hover:bg-[#F5F5F5]"
                          onClick={addTag}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="scale-75"
                          >
                            <path
                              d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                              fill="black"
                            />
                            <path
                              d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                              fill="black"
                            />
                            <path
                              d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                              fill="black"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                    {showWarning && (
                      <p className="text-sm" style={{ color: "red" }}>
                        You can add a maximum of 4 tags
                      </p>
                    )}
                    {showWarningEmpty && (
                      <p className="text-sm" style={{ color: "red" }}>
                        Please fill out the tags before adding them
                      </p>
                    )}
                    <div className="flex flex-col">
                      {Array.isArray(tags) &&
                        tags.map((tag, index) => (
                          <div
                            className="flex my-2 items-center justify-end"
                            key={index}
                          >
                            <span className="rounded-lg bg-[#F95C3D] text-white ml-2 p-1">
                              #{tag}
                            </span>
                            <Tooltip title="Remove Tag">
                              <button
                                className="rounded-lg ml-2 hover:bg-[#F5F5F5]"
                                onClick={() => removeTag(index)}
                              >
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="scale-75"
                                >
                                  <path
                                    d="M9.69965 13.9199H20.2996C20.5808 13.9199 20.8504 14.0337 21.0492 14.2362C21.248 14.4388 21.3596 14.7135 21.3596 14.9999C21.3596 15.2864 21.248 15.5611 21.0492 15.7636C20.8504 15.9661 20.5808 16.0799 20.2996 16.0799H9.69965C9.41852 16.0799 9.1489 15.9661 8.95012 15.7636C8.75133 15.5611 8.63965 15.2864 8.63965 14.9999C8.63965 14.7135 8.75133 14.4388 8.95012 14.2362C9.1489 14.0337 9.41852 13.9199 9.69965 13.9199Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M15 27.8571C16.6884 27.8571 18.3603 27.5246 19.9202 26.8784C21.4801 26.2323 22.8975 25.2853 24.0914 24.0914C25.2853 22.8975 26.2323 21.4801 26.8785 19.9202C27.5246 18.3603 27.8571 16.6884 27.8571 15C27.8571 13.3116 27.5246 11.6397 26.8785 10.0798C26.2323 8.51988 25.2853 7.10252 24.0914 5.90863C22.8975 4.71473 21.4801 3.76768 19.9202 3.12155C18.3603 2.47542 16.6884 2.14286 15 2.14286C11.5901 2.14286 8.31981 3.49744 5.90863 5.90863C3.49744 8.31981 2.14286 11.5901 2.14286 15C2.14286 18.4099 3.49744 21.6802 5.90863 24.0914C8.31981 26.5026 11.5901 27.8571 15 27.8571ZM15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30Z"
                                    fill="black"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Modal buttons */}
                  <div className="flex my-4 text-gray-700">
                    <div
                      className="rounded-lg p-2 mr-2 cursor-pointer"
                      style={{
                        border: "0.1px solid gainsboro",
                      }}
                      onClick={() => setIsUploadImg(!isUploadImg)}
                    >
                      <CollectionsOutlinedIcon />
                    </div>
                    <div
                      className="rounded-lg p-2 mx-2 cursor-pointer"
                      style={{
                        border: "0.1px solid gainsboro",
                      }}
                      onClick={() => setIsUploadLocation(!isUploadLocation)}
                    >
                      <LocationOnOutlinedIcon />
                    </div>
                    <div
                      className="rounded-lg p-2 mx-2 cursor-pointer"
                      style={{
                        border: "0.1px solid gainsboro",
                      }}
                      onClick={() => setIsUploadPrice(!isUploadPrice)}
                    >
                      <AttachMoneyIcon />
                    </div>
                    <div
                      className="rounded-lg p-2 mx-2 cursor-pointer"
                      style={{
                        border: "0.1px solid gainsboro",
                      }}
                      onClick={() =>
                        setIsUploadSocialMedia(!isUploadSocialMedia)
                      }
                    >
                      <PublicIcon />
                    </div>
                  </div>

                  {/* Post buttons */}
                  <div className="flex justify-end">
                    {isSubmit ? (
                      <button
                        type="submit"
                        className="
                    flex
                    justify-center
                    w-full
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                    hover:cursor-progress
                  "
                        disabled
                        style={{
                          background: "#F95C3D",
                          color: "white",
                        }}
                      >
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Posting...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="
                    w-full
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                    bg-[#F95C3D]
                    text-white
                    hover:bg-orange-600
                  "
                        disabled={isSubmit}
                      >
                        Post
                      </button>
                    )}
                  </div>
                </Typography>
              </>
            )}
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default HomeScreen;
