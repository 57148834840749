/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import axios from "axios";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";
import debounce from "debounce";
import { v4 as uuidv4 } from "uuid";
import { Box, Modal, Typography, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { format, differenceInDays, isSameDay } from "date-fns";
import { Helmet } from "react-helmet-async";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

const CHAT_SERVER_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}`;

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingChat: true };
    case "FETCHED":
      return { ...state, allChat: action.payload, loadingChat: false };
    case "FETCH_FAILED":
      return { ...state, loadingChat: false, errorChat: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingChatRoom: true };
    case "FETCHED":
      return { ...state, chatRoomId: action.payload, loadingChatRoom: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingChatRoom: false,
        errorChatRoom: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingChatCreation: true };
    case "FETCHED":
      return {
        ...state,
        chatCreation: action.payload,
        loadingChatCreation: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingChatCreation: false,
        errorChatCreation: action.payload,
      };
    default:
      return state;
  }
};

const reducer5 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingFollowing: true };
    case "FETCHED":
      return {
        ...state,
        followingList: action.payload,
        loadingFollowing: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingFollowing: false,
        errorFollowing: action.payload,
      };
    default:
      return state;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "0.5rem",
  outline: "none",
  // boxShadow: "0 2px 20px #F95C3D",
  p: 4,
};

function AllChatScreen() {
  const [userId, setUserId] = useState(null);
  const [targetUserId, setTargetUserId] = useState(null);
  const [checkFlagChat, setCheckFlagChat] = useState(null);
  const [checkFlagNewChat, setCheckFlagNewChat] = useState("");

  const [chatId, setChatId] = useState(null);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const socketRef = useRef(null);
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const chatContainerRef = useRef(null);
  const chatContainerRefMobile = useRef(null);
  const inputChatContainerRef = useRef(null);
  const inputChatContainerRefMobile = useRef(null);
  const [showNewChatModal, setShowNewChatModal] = useState(false);

  const handleNewChatModalClose = () => {
    setSelectedPerson(null);
    setQuery("");
    setShowNewChatModal(false);
  };

  const handleOpen = () => {
    setShowNewChatModal(true);
  };

  const [selectedPerson, setSelectedPerson] = useState(null);
  const [query, setQuery] = useState("");

  //open chat
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [openChatId, setOpenChatId] = useState("");
  const [openChatName, setOpenChatName] = useState("");
  const [openChatPicture, setOpenChatPicture] = useState("");
  const [openChatVerified, setOpenChatVerified] = useState(false);

  //modal states
  const [showDeleteChat, setShowDeleteChat] = useState(false);
  const [currChatId, setCurrChatId] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");

  let loc = useLocation();
  const [post, setPost] = useState(loc.state ? loc.state.post : null);
  let targetChatProfile = loc.state ? loc.state.targetUser : null;

  //hold behaviour mobile
  const [, setIsPressing] = useState(false);
  const timerRef = useRef(null);

  const [{ loading, error, dataUser }, dispatch] = useReducer(reducer, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [{ loadingChat, allChat }, dispatch2] = useReducer(reducer2, {
    allChat: [],
    loadingChat: true,
    errorChat: "",
  });

  const [{ chatRoomId }, dispatch3] = useReducer(reducer3, {
    chatRoomId: [],
    loadingChatRoom: true,
    errorChatRoom: "",
  });

  const [{ followingList }, dispatch5] = useReducer(reducer5, {
    followingList: [],
    loadingFollowing: true,
    errorFollowing: "",
  });

  // Getting User Info
  useEffect(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        navigate("/");
        return;
      }

      dispatch({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch({
          type: "FETCHED",
          payload: data.data,
        });
        setUserId(data.data.user_id);
      } catch (error) {
        dispatch({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [navigate, userInfo]);

  // Getting All Chats
  useEffect(async () => {
    if (userInfo !== null) {
      dispatch2({
        type: "FETCHING",
      });
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllChats`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );
        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
      }
    }
  }, [userInfo, chatId, message]);

  // Sending Message

  const sendMessage = () => {
    if (!message.trim() || !userId || !chatRoomId) return;
    const newId = uuidv4();
    const timestamp = new Date().toISOString();

    const newMessage = {
      id: newId,
      user_id: userId,
      message: message,
      timestamp: timestamp,
    };

    socketRef.current.emit(
      "chat message",
      { chatRoomId, newId, userId, message, timestamp },
      (acknowledgement) => {
        // console.log(acknowledgement);
        if (acknowledgement) {
          setMessages((prevMessages) => {
            // Check if the new message ID already exists in the messages
            if (prevMessages.some((item) => item.id === newId)) {
              // If it's a duplicate, return the previous messages unchanged
              return prevMessages;
            }

            // If it's not a duplicate, add the new message to the state
            return [...prevMessages, newMessage];
          });
          setMessage(""); // Clear the input
          sendNotification();
        } else {
          console.error("Failed to send message");
        }
      }
    );
  };

  const sendNotification = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/sendNotif`,
        {
          // postId: post.id,
          targetUserId: openChatId,
          notifNumber: 4,
          // detailId: like_id,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
    } catch (error) { }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        debouncedSendMessage();
      }
    };

    // Debounce the sendMessage function
    const debouncedSendMessage = debounce(sendMessage, 500);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Clear the debounce timer
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [message]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
    }
  };
  const debouncedSendMessage = debounce(sendMessage, 500);

  useEffect(() => { }, [isHistoryLoaded, messages]);

  // New Chat Checking going to openChat

  const checkRedirectChat = async (targetId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getChatRoom?targetUserId=${targetId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      if (res.data && res.data.data && res.data.data.id) {
        setCheckFlagChat(res.data.data.id);
      } else {
        setCheckFlagChat(null);
      }
    } catch (err) {
      console.error(err);
    }
    setQuery("");
    setShowNewChatModal(false);
  };

  const checkNewChat = async (targetId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getChatRoom?targetUserId=${targetId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      if (res.data && res.data.data && res.data.data.id) {
        setCheckFlagNewChat(res.data.data.id);
      } else {
        setCheckFlagNewChat(null);
      }
    } catch (err) {
      console.error(err);
    }
    setQuery("");
    setShowNewChatModal(false);
  };

  const createNewChat = async (targetId) => {
    try {
      const res = await axios.post(
        `${CHAT_SERVER_URL}/api/users/chats`,
        { targetUserId: targetId },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      setChatId(res.data.data.id);
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    if (dataUser && post && dataUser.user_id !== post.user_id) {
      checkRedirectChat(post.user_id);
    }
  }, [post]);

  useEffect(() => {
    if (
      dataUser &&
      targetChatProfile &&
      dataUser.user_id !== targetChatProfile
    ) {
      checkRedirectChat(targetChatProfile.user_id);
    }
  }, [targetChatProfile]);

  useEffect(() => {
    if (checkFlagChat == null && post) {
      createNewChat(post.user_id);
    } else if (dataUser && post) {
      let tempChat = {
        first_userid: dataUser.user_id,
        second_userid: post.user_id,
        username: post.username,
        picture: post.picture,
      };
      setTargetUserId(post.user_id);
      openChat(tempChat);
    } else if (
      checkFlagChat == null &&
      targetChatProfile &&
      targetChatProfile.user_id
    ) {
      createNewChat(targetChatProfile.user_id);
    } else if (dataUser && targetChatProfile && targetChatProfile.user_id) {
      let tempChat = {
        first_userid: dataUser.user_id,
        second_userid: targetChatProfile.user_id,
        username: targetChatProfile.username,
        picture: targetChatProfile.picture,
      };
      setTargetUserId(targetChatProfile.user_id);
      openChat(tempChat);
    }
  }, [checkFlagChat]);

  useEffect(() => {
    if (checkFlagNewChat == null && selectedPerson) {
      createNewChat(selectedPerson.target_userid);
    } else if (dataUser && selectedPerson) {
      let tempChat = {
        first_userid: dataUser.user_id,
        second_userid: selectedPerson.target_userid,
        name: selectedPerson.username,
        picture: selectedPerson.picture,
      };
      setTargetUserId(selectedPerson.target_userid);
      openChat(tempChat);
    }
  }, [checkFlagNewChat]);

  // on new chat creation
  useEffect(() => {
    if (selectedPerson && dataUser) {
      let tempChat = {
        first_userid: dataUser.user_id,
        second_userid: selectedPerson.target_userid,
        name: selectedPerson.username,
        picture: selectedPerson.picture,
      };
      setTargetUserId(selectedPerson.target_userid);
      openChat(tempChat);
    }
  }, [chatId]);

  // Opening a chat
  const openChat = async (datachat) => {
    console.log("DATA CHAT: ", datachat);
    setCurrChatId(datachat.chatid);
    let flag = 0;
    let targetId = null;
    setOpenChatName(datachat.username);

    if (datachat.picture) {
      setOpenChatPicture(datachat.picture);
    } else {
      setOpenChatPicture("../images/pp/defaultpp.png");
    }

    setOpenChatVerified(datachat.verified);

    if (dataUser.user_id === datachat.first_userid) {
      targetId = datachat.second_userid;
    } else {
      targetId = datachat.first_userid;
    }
    setOpenChatId(targetId);

    dispatch3({
      type: "FETCHING",
    });

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getChatRoom?targetUserId=${targetId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      if (data.data.id === chatRoomId) {
        flag = 1;
      }

      dispatch3({
        type: "FETCHED",
        payload: data.data.id,
      });
    } catch (error) {
      dispatch3({
        type: "FETCH_FAILED",
        payload: error.message,
      });
    }

    setIsOpenChat(true);
    if (flag === 1) {
      flag = 0;
    } else {
      setMessages([]);
    }
    setIsHistoryLoaded(false);
  };

  //modal functions
  const handleCloseDeleteChatModal = () => {
    setShowDeleteChat(false);
  };

  const handleDeleteChat = async () => {
    setPost(null);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/deleteChat`,
        {
          chatId: currChatId,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      setShowDeleteChat(false);
      toast.success("Chat Deleted");
      setChatId(null);
      window.location.reload();
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchUnreadMessages = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllChats`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      dispatch2({
        type: "FETCHED",
        payload: data.data,
      });
    } catch (error) {
      dispatch2({
        type: "FETCH_FAILED",
        payload: error.message,
      });
    }
  };

  // After Opening a chat, we find the messages
  useEffect(() => {
    if (!chatRoomId || !dataUser || chatRoomId.length === 0) return;
    socketRef.current = io(`${CHAT_SERVER_URL}?chatId=${chatRoomId}`);
    socketRef.current.emit("join", dataUser);

    socketRef.current.on("connected", () => {
      console.log("Connected to socket server");
    });
    socketRef.current.on("chat message", (msg) => {
      if (msg && msg[0] && msg[0].id == null) {
        return;
      }

      setMessages((prevMessages) => {
        // Check for duplicates based on message ID
        const uniqueMessages = [
          ...new Map(
            [...prevMessages, ...msg].map((item) => [item.id, item])
          ).values(),
        ];
        fetchUnreadMessages();
        return uniqueMessages;
      });
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [chatRoomId]);

  useEffect(() => {
    if (chatContainerRef.current !== null) {
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      inputChatContainerRef.current.focus();
    }
    if (chatContainerRefMobile.current !== null) {
      chatContainerRefMobile.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      inputChatContainerRefMobile.current.focus();
    }
  }, [messages, isOpenChat]);

  const handleBack = () => {
    setOpenChatId("");
    setOpenChatName("");
    setOpenChatPicture("");
    setOpenChatVerified("");
    setIsOpenChat(false);
  };

  useEffect(async () => {
    if (userInfo === null) {
      navigate("/");
      return;
    } else if (dataUser === undefined || dataUser.length === 0) {
      return;
    }

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/checkFollowing?findUserId=${dataUser.user_id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch5({
        type: "FETCHED",
        payload: data.data,
      });
    } catch (error) {
      dispatch5({
        type: "FETCH_FAILED",
        payload: error.message,
      });
      toast.error(getError(error));
    }
  }, [dataUser]);

  function SetProfilePicture({ person }) {
    const isNull = person.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture picture={person.picture} />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="rounded-full w-8 h-8"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
        referrerPolicy="no-referrer"
      />
    );
  }

  function ProfilePicture({ picture }) {
    return (
      <img
        className="rounded-full w-8 h-8"
        src={picture}
        alt="profilepicture"
        referrerPolicy="no-referrer"
      />
    );
  }

  const filteredFollowingList =
    query === ""
      ? followingList
      : followingList.filter((followingList) => {
        return followingList.username
          .toLowerCase()
          .includes(query.toLowerCase());
      });

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      setOpenChatId("");
      setOpenChatName("");
      setOpenChatPicture("");
      setOpenChatVerified("");
      setIsOpenChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const UploadDate = ({ timestamp }) => {
    let currentDate = new Date();
    let uploadDate = new Date(timestamp);
    let uploadDateLimit = new Date(timestamp).setHours(0, 0, 0, 0);

    let date = "";

    if (!isSameDay(currentDate, uploadDateLimit)) {
      let diffDate = differenceInDays(currentDate, uploadDateLimit);
      if (diffDate >= 7) {
        date = format(uploadDate, "dd/MM/yyyy");
        return date;
      } else if (diffDate >= 2) {
        date = format(uploadDate, "eeee");
        return date;
      } else {
        date = "Yesterday";
        return date;
      }
    } else {
      date = format(uploadDate, "kk:mm a");
      return date;
    }
  };

  const UploadDateChat = ({ timestamp }) => {
    let date = format(new Date(timestamp), "kk:mm a");
    return date;
  };

  // Get messages grouped by day
  const groupMessagesByDay = () => {
    const groupedMessages = {};
    let currentDate = new Date();

    let date = "";

    messages.forEach((message) => {
      let uploadDate = new Date(message.timestamp);
      let uploadDateLimit = new Date(message.timestamp).setHours(0, 0, 0, 0);
      if (!isSameDay(currentDate, uploadDateLimit)) {
        let diffDate = differenceInDays(currentDate, uploadDateLimit);
        if (diffDate >= 7) {
          date = format(uploadDate, "dd/MM/yyyy");
        } else if (diffDate >= 2) {
          date = format(uploadDate, "eeee");
        } else {
          date = "Yesterday";
        }
      } else {
        date = "Today";
      }

      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDay();

  const handleMouseDown = (chat, event) => {
    setIsPressing(true);
    timerRef.current = setTimeout(() => {
      setShowDeleteChat(true);
      setCurrChatId(chat.chatid);
    }, 500);
  };

  const handleMouseUp = () => {
    clearTimeout(timerRef.current);
    setIsPressing(false);
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
    setIsPressing(false);
  };

  const handleButtonReport = () => {
    setShowReportModal(true);
  };

  const handleCancelReport = () => {
    setShowReportModal(false);
    setSelectedReason("");
  };

  const handleReportUser = async (reasonId) => {
    console.log("Report Button Clicked");
    console.log("Target User ID: ", openChatId);
    console.log("Chat ID: ", currChatId);
    console.log("Reason ID: ", reasonId);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/blockUserChat`,
        {
          chatId: currChatId,
          targetUserId: openChatId,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      toast.sucess("User Reported & Blocked.")
    } catch (error) {
      toast.error(getError(error));
    }
  }

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const modalReportStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: "8px",
  };

  return (
    <>
      <Helmet>
        <title>Chat | SiapHub</title>
      </Helmet>
      {loading ? (
        <div className="flex justify-center pt-6">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : (
        <>
          {/* Desktop */}
          <div className="hidden lg:flex h-full">
            <div className="flex h-full w-full">
              <div className="w-1/3 xl:w-1/4 overflow-y-auto container border-l-2 border-[#F5F5F5] bg-white">
                {/* <div className="flex py-5 px-3 border-b-2 bg-[#FFE4CC] border-[#F5F5F5]"> */}
                <div className="flex py-3 px-2 my-3 mx-2 bg-[#FFE4CC] rounded-lg shadow-md">
                  {/* <div className="flex py-2 px-2 my-3 mx-2 border-2 border-[#FFE4CC] rounded-lg"> */}
                  <div className="flex items-center ">
                    <img
                      className="inline-block rounded-full w-10 h-10"
                      src={
                        dataUser.picture
                          ? dataUser.picture
                          : "../images/pp/defaultpp.png"
                      }
                      alt="profilepicture"
                    />
                  </div>
                  <div className="min-w-[100px] flex flex-grow flex-col ml-2">
                    <div className="flex truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg">
                      {dataUser.username}
                      <div className="flex items-center justify-center ml-2">
                        {dataUser.verified ? (
                          <img
                            className="w-4 h-4"
                            alt="verified"
                            src="../../images/icon_done.png"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="truncate text-ellipsis overflow-hidden text-sm">
                      You
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <Tooltip
                      className="hover:cursor-pointer"
                      title="Create Message"
                    >
                      <div
                        className="rounded-lg hover:bg-[#E8CAAD]"
                        onClick={handleOpen}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="scale-75"
                        >
                          <path
                            d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                            fill="black"
                          />
                          <path
                            d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                            fill="black"
                          />
                          <path
                            d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                    <Modal
                      open={showNewChatModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="modal-notification-style">
                        <IconButton
                          aria-label="close"
                          onClick={handleNewChatModalClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          New Chat
                        </Typography>
                        <Combobox
                          value={selectedPerson}
                          onChange={setSelectedPerson}
                        >
                          <div className="relative mt-4">
                            <Combobox.Input
                              className={clsx(
                                "w-full rounded-lg bg-white py-1.5 pr-8 pl-3 text-sm/6 text-black",
                                "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                              )}
                              displayValue={(person) => person?.username}
                              placeholder="Select from your following..."
                              onChange={(event) => setQuery(event.target.value)}
                              style={{
                                border: "0.1px solid gainsboro",
                              }}
                            />
                          </div>

                          <Combobox.Options
                            static
                            anchor="bottom"
                            className="w-[var(--input-width)] bg-white rounded-lg overflow-y-auto max-h-64 border border-white/5 bg-stone-100 py-2 mt-2 [--anchor-gap:var(--spacing-1)] empty:hidden"
                            style={{
                              border: "0.1px solid gainsboro",
                            }}
                          >
                            {filteredFollowingList.length === 0 &&
                              query !== "" ? (
                              <div className="relative cursor-default select-none py-2 pr-8 pl-3 text-gray-700">
                                Nothing found.
                              </div>
                            ) : (
                              followingList.map((person) => (
                                <Combobox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    `group flex cursor-default items-center gap-2 py-2 pr-8 pl-3 select-none cursor-pointer ${active
                                      ? "bg-[#F5F5F5]"
                                      : "text-gray-900"
                                    }`
                                  }
                                  
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex">
                                        <SetProfilePicture person={person} />
                                        <div className="flex items-center ml-3">
                                          {person.username}
                                          <div className="flex items-center justify-center ml-2">
                                            {person.verified ? (
                                              <img
                                                className="w-4 h-4"
                                                alt="verified"
                                                src="../../images/icon_done.png"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Combobox>
                        <div className="flex justify-center mt-3">
                          <button
                            type="submit"
                            className="w-full py-2 px-4 rounded-lg focus:outline-none bg-[#F95C3D] text-white hover:bg-orange-600"
                            onClick={() =>
                              checkNewChat(selectedPerson.target_userid)
                            }
                          >
                            Chat
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
                <ul>
                  {Array.isArray(allChat) &&
                    allChat.map((chat) => (
                      <li
                        key={chat.chatid}
                        className={`group flex py-2 px-2 mx-2 hover:bg-[#F5F5F5] rounded-lg hover:cursor-pointer ${openChatId === chat.first_userid ||
                          openChatId === chat.second_userid
                          ? "bg-[#F5F5F5]"
                          : ""
                          }`}
                        onClick={() => openChat(chat)}
                      >
                        <div className="flex items-center">
                          <img
                            className="inline-block rounded-full w-10 h-10"
                            src={
                              chat.picture
                                ? chat.picture
                                : "../images/pp/defaultpp.png"
                            }
                            alt="profilepicture"
                          />
                        </div>

                        <div className="min-w-[50px] flex flex-col ml-2 flex-grow ">
                          <div className="flex justify-between">
                            <div className="flex text-left truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg">
                              {chat.username}
                              <div className="flex items-center justify-center ml-2">
                                {chat.verified ? (
                                  <img
                                    className="w-4 h-4"
                                    alt="verified"
                                    src="../../images/icon_done.png"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="flex items-center text-right text-xs">
                              <UploadDate timestamp={chat.timestamp} />
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div
                              className={`truncate text-ellipsis overflow-hidden text-sm ${chat.unread_count > 0 ? "font-bold" : ""
                                }`}
                            >
                              {chat.message}
                            </div>
                            <div className="flex">
                              {chat.unread_count > 99 ? (
                                <span className="flex items-center">
                                  <span className="flex rounded-full h-6 w-6 bg-[#F95C3D] text-[11px] text-white justify-center items-center">
                                    99+
                                  </span>
                                </span>
                              ) : chat.unread_count > 0 ? (
                                <span className="flex items-center">
                                  <span className="flex rounded-full h-5 w-5 bg-[#F95C3D] text-[12px] text-white justify-center items-center">
                                    {chat.unread_count}
                                  </span>
                                </span>
                              ) : null}

                              <div className="flex justify-center items-center ml-2">
                                <Tooltip
                                  title="Delete Message"
                                  placement="top"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setShowDeleteChat(true);
                                    setCurrChatId(chat.chatid);
                                  }}
                                >
                                  <div>
                                    <svg
                                      width="12"
                                      height="15"
                                      viewBox="0 0 12 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hidden opacity-75 group-hover:flex transition duration-300 ease-in-out transform active:scale-75"
                                    >
                                      <path
                                        d="M11.6667 0.833333H8.75L7.91667 0H3.75L2.91667 0.833333H0V2.5H11.6667M0.833333 13.3333C0.833333 13.7754 1.00893 14.1993 1.32149 14.5118C1.63405 14.8244 2.05797 15 2.5 15H9.16667C9.60869 15 10.0326 14.8244 10.3452 14.5118C10.6577 14.1993 10.8333 13.7754 10.8333 13.3333V3.33333H0.833333V13.3333Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="h-full w-2/3 xl:w-3/4 flex border-l-2 border-[#F5F5F5] flex-col justify-start">
                {/* Add conditional for if have comment list */}
                {isOpenChat === false ? (
                  <div className="flex py-5 pr-5 border-1 bg-white h-full justify-center items-center">
                    <div className="flex flex-col">
                      <div className="flex justify-center">
                        <img
                          src="./images/siaphub_logo.png"
                          alt="logo_siaphub"
                          className="h-44 w-44 opacity-70 "
                        />
                      </div>
                      <p className="text-[#F95C3D] text-2xl">
                        Start sending a message by clicking
                      </p>
                      <div className="flex justify-center mt-2">
                        <Tooltip
                          className="hover:cursor-pointer"
                          title="Create Message"
                        >
                          <div
                            className="rounded-lg hover:bg-[#FFE5CC] p-1"
                            onClick={handleOpen}
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                                fill="black"
                              />
                              <path
                                d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                                fill="black"
                              />
                              <path
                                d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : (
                  // header
                  <>
                    <div className="flex py-3 px-2 mt-3 mb-1 mx-2 bg-white border-[#F5F5F5] rounded-lg shadow-md">
                      <div className="flex items-center relative">
                        <img
                          className="inline-block rounded-full w-10 h-10"
                          src={openChatPicture}
                          alt="chatprofilepicture"
                        />
                        <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
                      </div>
                      <div className="min-w-[100px] flex flex-grow flex-col ml-2">
                        <div className="flex">
                          <div className="flex truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg">
                            {openChatName}
                            <div className="flex items-center justify-center ml-2">
                              {openChatVerified ? (
                                <img
                                  className="w-4 h-4"
                                  alt="verified"
                                  src="../../images/icon_done.png"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <Tooltip
                            onClick={() => handleButtonReport()}
                            title="Report User"
                            className="cursor-pointer"
                          >
                            <img
                              className="h-5 w-5 m-1"
                              src="../images/report_btn.png"
                              alt="Report Button"
                            ></img>
                          </Tooltip>
                        </div>
                        <div className="truncate text-ellipsis overflow-hidden text-sm text-gray-500">
                          Active
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex-auto py-5 overflow-y-auto overflow-x-hidden"
                    // style={{ maxHeight: "calc(100vh - 100px)" }}
                    >
                      <ul className="pl-4 pr-4 space-y-4">
                        {Object.keys(groupedMessages).map((date) => (
                          <li key={date} className="space-y-2">
                            <div className="flex justify-center">
                              <div
                                className={`bg-white px-2 rounded-lg shadow-md ${Object.keys(groupedMessages).length === 1 &&
                                  date === "Today"
                                  ? "hidden"
                                  : "flex"
                                  }`}
                              >
                                {date}
                              </div>
                            </div>
                            {groupedMessages[date].map((msg) => (
                              <div
                                style={{
                                  textAlign:
                                    msg.user_id === dataUser.user_id
                                      ? "-webkit-right"
                                      : "",
                                }}
                              >
                                <div
                                  className={`${msg.user_id === dataUser.user_id
                                    ? "flex justify-end"
                                    : "flex justify-start"
                                    }`}
                                >
                                  <img
                                    className={`inline-block rounded-full w-10 h-10 mr-2 ${msg.user_id === dataUser.user_id
                                      ? "hidden"
                                      : "flex"
                                      }`}
                                    src={openChatPicture}
                                    alt="chatprofilepicture"
                                  />
                                  <div
                                    className={`items-end text-xs mr-2 ${msg.user_id === dataUser.user_id
                                      ? "flex"
                                      : "hidden"
                                      }`}
                                  >
                                    <UploadDateChat timestamp={msg.timestamp} />
                                  </div>
                                  <div
                                    key={msg.id}
                                    className={`px-3.5 py-2 rounded-2xl justify-start items-center gap-3 inline-flex ${msg.user_id === dataUser.user_id
                                      ? "rounded-tr-none bg-[#FFE4CC] self-start"
                                      : "rounded-tl-none bg-gray-100 self-end"
                                      }`}
                                    style={{
                                      width: "fit-content",
                                      maxWidth: "50%",
                                    }}
                                  >
                                    {msg.message && (
                                      <div>
                                        <div
                                          className={`flex font-bold ${msg.user_id === dataUser.user_id
                                            ? "justify-end"
                                            : ""
                                            }`}
                                        >
                                          {msg.user_id === dataUser.user_id
                                            ? "You"
                                            : openChatName}
                                          {msg.user_id !== dataUser.user_id &&
                                            openChatVerified ? (
                                            <div className="flex items-center justify-center ml-2">
                                              <img
                                                className="w-4 h-4"
                                                alt="verified"
                                                src="../../images/icon_done.png"
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                        <div>{msg.message}</div>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={`items-end text-xs ml-2 ${msg.user_id === dataUser.user_id
                                      ? "hidden"
                                      : "flex"
                                      }`}
                                  >
                                    <UploadDateChat timestamp={msg.timestamp} />
                                  </div>

                                  <img
                                    className={`inline-block rounded-full w-10 h-10 ml-2 ${msg.user_id === dataUser.user_id
                                      ? "flex"
                                      : "hidden"
                                      }`}
                                    src={
                                      dataUser.picture
                                        ? dataUser.picture
                                        : "../images/pp/defaultpp.png"
                                    }
                                    alt="profilepicture"
                                  />
                                </div>
                              </div>
                            ))}
                          </li>
                        ))}
                        <div ref={chatContainerRef}></div>
                      </ul>
                    </div>

                    <div className="flex justify-center items-end bg-white">
                      <div className="bg-[#F5F5F5] rounded-lg w-full flex items-center py-1 px-2 my-4 mx-2">
                        <TextareaAutosize
                          maxRows={10}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          className="bg-transparent w-full outline-none py-2"
                          placeholder="Type your message..."
                          ref={inputChatContainerRef}
                          onKeyDown={handleKeyDown}
                          style={{ resize: "none" }}
                        />
                        <button
                          type="button"
                          onClick={debouncedSendMessage}
                        // className="bg-[#F95C3D] text-white ml-2 px-4 py-2 rounded-lg"
                        >
                          <SendIcon
                            className="ml-2"
                            sx={{ color: "#F95C3D" }}
                          />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Mobile */}
          <div className="lg:hidden h-[86vh]">
            <div className="flex h-full w-full">
              {/* Add conditional for if have comment list */}
              {isOpenChat === false ? (
                <div className="w-full overflow-y-auto bg-white">
                  <div className="flex py-3 px-2 my-3 mx-2 bg-[#FFE4CC] rounded-lg shadow-md">
                    <div className="flex items-center ">
                      <img
                        className="inline-block rounded-full w-10 h-10"
                        src={
                          dataUser.picture
                            ? dataUser.picture
                            : "../images/pp/defaultpp.png"
                        }
                        alt="profilepicture"
                      />
                    </div>
                    <div className="min-w-[100px] flex flex-grow flex-col ml-2">
                      <div className="truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg inline-flex items-center">
                        {dataUser.username}
                        <div className="flex items-center ml-2">
                          {dataUser.verified ? (
                            <img
                              className="w-4 h-4"
                              alt="verified"
                              src="../../images/icon_done.png"
                            ></img>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="truncate text-ellipsis overflow-hidden text-sm">
                        You
                      </div>
                    </div>

                    <div className="flex justify-center items-center">
                      <Tooltip
                        className="hover:cursor-pointer"
                        title="Create Message"
                      >
                        <div
                          className="rounded-lg hover:bg-[#E8CAAD]"
                          onClick={handleOpen}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="scale-75"
                          >
                            <path
                              d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                              fill="black"
                            />
                            <path
                              d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                              fill="black"
                            />
                            <path
                              d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <ul>
                    {Array.isArray(allChat) &&
                      allChat.map((chat) => (
                        <li
                          key={chat.chatid}
                          className={`group flex py-2 px-2 mx-2 active:bg-[#F5F5F5] hover:rounded-lg hover:cursor-pointer ${openChatId === chat.first_userid
                            ? "bg-[#F5F5F5]"
                            : ""
                            }`}
                          onMouseDown={(event) => handleMouseDown(chat, event)}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={(event) => handleMouseDown(chat, event)}
                          onTouchEnd={handleMouseUp}
                          onClick={() => openChat(chat)}
                        >
                          <div className="flex items-center">
                            <img
                              className="inline-block rounded-full w-10 h-10"
                              src={
                                chat.picture
                                  ? chat.picture
                                  : "../images/pp/defaultpp.png"
                              }
                              alt="profilepicture"
                            />
                          </div>

                          <div className="min-w-[50px] flex flex-col ml-2 flex-grow ">
                            <div className="flex justify-between">
                              <div className="flex text-left truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg">
                                {chat.username}
                                <div className="flex items-center justify-center ml-2">
                                  {chat.verified ? (
                                    <img
                                      className="w-4 h-4"
                                      alt="verified"
                                      src="../../images/icon_done.png"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center text-right text-xs">
                                <UploadDate timestamp={chat.timestamp} />
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div
                                className={`truncate text-ellipsis overflow-hidden text-sm ${chat.unread_count > 0 ? "font-bold" : ""
                                  }`}
                              >
                                {chat.message}
                              </div>
                              <div className="flex">
                                {chat.unread_count > 99 ? (
                                  <span className="flex items-center">
                                    <span className="flex rounded-full h-6 w-6 bg-[#F95C3D] text-[11px] text-white justify-center items-center">
                                      99+
                                    </span>
                                  </span>
                                ) : chat.unread_count > 0 ? (
                                  <span className="flex items-center">
                                    <span className="flex rounded-full h-5 w-5 bg-[#F95C3D] text-[12px] text-white justify-center items-center">
                                      {chat.unread_count}
                                    </span>
                                  </span>
                                ) : null}

                                <div className="flex justify-center items-center ml-2">
                                  <Tooltip
                                    title="Delete Message"
                                    placement="top"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setShowDeleteChat(true);
                                      setCurrChatId(chat.chatid);
                                    }}
                                  >
                                    <div>
                                      <svg
                                        width="12"
                                        height="15"
                                        viewBox="0 0 12 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="hidden opacity-75 group-hover:flex transition duration-300 ease-in-out transform active:scale-75"
                                      >
                                        <path
                                          d="M11.6667 0.833333H8.75L7.91667 0H3.75L2.91667 0.833333H0V2.5H11.6667M0.833333 13.3333C0.833333 13.7754 1.00893 14.1993 1.32149 14.5118C1.63405 14.8244 2.05797 15 2.5 15H9.16667C9.60869 15 10.0326 14.8244 10.3452 14.5118C10.6577 14.1993 10.8333 13.7754 10.8333 13.3333V3.33333H0.833333V13.3333Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ) : (
                <div className="h-full w-full flex border-[#D1D1D1] flex-col justify-start">
                  <div className="flex py-3 px-2 mt-3 mb-1 mx-2 bg-white border-[#F5F5F5] rounded-lg shadow-md">
                    <div
                      className="flex items-center mr-5 cursor-pointer"
                      onClick={handleBack}
                    >
                      <svg
                        width="30"
                        height="26"
                        viewBox="0 0 30 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="scale-50"
                      >
                        <path
                          d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div className="flex items-center relative">
                      <img
                        className="inline-block rounded-full w-10 h-10"
                        src={openChatPicture}
                        alt="chatprofilepicture"
                      />
                      <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
                    </div>
                    <div className="min-w-[100px] flex flex-grow flex-col ml-2">
                      <div className="flex">
                        <div className="flex truncate text-ellipsis overflow-hidden font-bold text-base lg:text-lg">
                          {openChatName}
                          <div className="flex items-center justify-center ml-2">
                            {openChatVerified ? (
                              <img
                                className="w-4 h-4"
                                alt="verified"
                                src="../../images/icon_done.png"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <Tooltip
                          onClick={() => handleButtonReport()}
                          title="Report User"
                          className="cursor-pointer"
                        >
                          <img
                            className="h-5 w-5 m-1"
                            src="../images/report_btn.png"
                            alt="Report Button"
                          ></img>
                        </Tooltip>
                      </div>
                      <div className="truncate text-ellipsis overflow-hidden text-sm text-gray-500">
                        Active
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-auto py-5 overflow-y-auto overflow-x-hidden"
                  // style={{ maxHeight: "calc(100vh - 100px)" }}
                  >
                    <ul className="pl-4 pr-4 space-y-4">
                      {Object.keys(groupedMessages).map((date) => (
                        <li key={date} className="space-y-2">
                          <div className="flex justify-center">
                            <div
                              className={`bg-white px-2 rounded-lg shadow-md ${Object.keys(groupedMessages).length === 1 &&
                                date === "Today"
                                ? "hidden"
                                : "flex"
                                }`}
                            >
                              {date}
                            </div>
                          </div>
                          {groupedMessages[date].map((msg) => (
                            <div
                              style={{
                                textAlign:
                                  msg.user_id === dataUser.user_id
                                    ? "-webkit-right"
                                    : "",
                              }}
                            >
                              <div
                                className={`${msg.user_id === dataUser.user_id
                                  ? "flex justify-end"
                                  : "flex justify-start"
                                  }`}
                              >
                                <img
                                  className={`inline-block rounded-full w-10 h-10 mr-2 ${msg.user_id === dataUser.user_id
                                    ? "hidden"
                                    : "flex"
                                    }`}
                                  src={openChatPicture}
                                  alt="chatprofilepicture"
                                />
                                <div
                                  className={`items-end text-xs mr-2 ${msg.user_id === dataUser.user_id
                                    ? "flex"
                                    : "hidden"
                                    }`}
                                >
                                  <UploadDateChat timestamp={msg.timestamp} />
                                </div>
                                <div
                                  key={msg.id}
                                  className={`px-3.5 py-2 rounded-2xl justify-start items-center gap-3 inline-flex ${msg.user_id === dataUser.user_id
                                    ? "rounded-tr-none bg-[#FFE4CC] self-start"
                                    : "rounded-tl-none bg-gray-100 self-end"
                                    }`}
                                  style={{
                                    width: "fit-content",
                                    maxWidth: "50%",
                                  }}
                                >
                                  {msg.message && (
                                    <div>
                                      <div
                                        className={`flex font-bold ${msg.user_id === dataUser.user_id
                                          ? "justify-end"
                                          : ""
                                          }`}
                                      >
                                        {msg.user_id === dataUser.user_id
                                          ? "You"
                                          : openChatName}
                                        {msg.user_id !== dataUser.user_id &&
                                          openChatVerified ? (
                                          <div className="flex items-center justify-center ml-2">
                                            <img
                                              className="w-4 h-4"
                                              alt="verified"
                                              src="../../images/icon_done.png"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div>{msg.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`items-end text-xs ml-2 ${msg.user_id === dataUser.user_id
                                    ? "hidden"
                                    : "flex"
                                    }`}
                                >
                                  <UploadDateChat timestamp={msg.timestamp} />
                                </div>

                                <img
                                  className={`inline-block rounded-full w-10 h-10 ml-2 ${msg.user_id === dataUser.user_id
                                    ? "flex"
                                    : "hidden"
                                    }`}
                                  src={
                                    dataUser.picture
                                      ? dataUser.picture
                                      : "../images/pp/defaultpp.png"
                                  }
                                  alt="profilepicture"
                                />
                              </div>
                            </div>
                          ))}
                        </li>
                      ))}
                      <div ref={chatContainerRefMobile}></div>
                    </ul>
                  </div>

                  <div className="flex justify-center items-end bg-white pb-5">
                    <div className="bg-[#F5F5F5] rounded-lg w-full flex items-center py-1 px-2 my-4 mx-2">
                      <TextareaAutosize
                        maxRows={5}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="bg-transparent w-full outline-none py-2"
                        placeholder="Type your message..."
                        ref={inputChatContainerRefMobile}
                        onKeyDown={handleKeyDown}
                        style={{ resize: "none" }}
                      />
                      <button
                        type="button"
                        onClick={debouncedSendMessage}
                      // className="bg-[#F95C3D] text-white ml-2 px-4 py-2 rounded-lg"
                      >
                        <SendIcon className="ml-2" sx={{ color: "#F95C3D" }} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <Modal
        open={showDeleteChat}
        onClose={handleCloseDeleteChatModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            Delete this chat?
          </Typography>
          <div className="flex justify-center">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleDeleteChat}
            >
              Yes
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleCloseDeleteChatModal}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      {/* Modal confirmation for report/block user */}
      <Modal
        open={showReportModal}
        onClose={handleCancelReport}
        aria-labelledby="report-post-title"
        aria-describedby="report-post-description"
      >
        <Box sx={modalReportStyle}>
          <Typography id="report-post-title" variant="h6" component="h2">
            Reason to report & block user
          </Typography>
          <RadioGroup
            aria-labelledby="report-post-reasons"
            name="report-reasons"
            value={selectedReason}
            onChange={handleReasonChange}
            sx={{ mt: 2 }}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Inappropriate"
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Scam"
            />
            <FormControlLabel value="3" control={<Radio />} label="Spam" />
            <FormControlLabel
              value="4"
              control={<Radio />}
              label="Hate Speech"
            />
            <FormControlLabel value="5" control={<Radio />} label="Violence" />
            <FormControlLabel value="6" control={<Radio />} label="Other" />
          </RadioGroup>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button onClick={handleCancelReport} sx={{ mr: 2 }}>
              Cancel Report
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleReportUser(selectedReason)}
              disabled={!selectedReason}
            >
              Report & Block User
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AllChatScreen;
